import {
  container,
  cardTitle,
  title,
  mlAuto,
  mrAuto,
  main,
  whiteColor,
  mainRaised,
  grayColor,
  hexToRgb,
  blackColor
} from "assets/jss/material-kit-pro-react.jsx";
import imagesStyle from "assets/jss/material-kit-pro-react/imagesStyles.jsx";
import tooltipsStyle from "assets/jss/material-kit-pro-react/tooltipsStyle.jsx";
import termsStyle from "ps-assets/jss/termsOfUsageStyle.jsx";
import productListStyle from "ps-assets/jss/productListStyle.jsx";
const profilePageStyle = theme => ({
  container,
  ...productListStyle,
  ...termsStyle,
  ...imagesStyle,
  ...tooltipsStyle,
  cardTitleWhite: {
    ...cardTitle,
    color: whiteColor + "  !important",
    float: "left"
  },
  cardTitle,
  profile: {
    textAlign: "center",
    "& img": {
      maxWidth: "160px",
      width: "100%",
      margin: "0 auto",
      transform: "translate3d(0, -50%, 0)"
    }
  },
  description: {
    margin: "1.071rem auto 0",
    maxWidth: "600px",
    color: grayColor[0]
  },
  textCenter: {
    textAlign: "center !important"
  },
  name: {
    marginTop: "-80px"
  },

  main: {
    ...main
  },
  mainRaised: {
    ...mainRaised
  },
  title: {
    ...title,
    position: "relative",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none"
  },
  testimonialstitlefix: {
    ...title,
    position: "relative",
    marginTop: "100px",
    minHeight: "32px",
    textDecoration: "none",
    [theme.breakpoints.down("md")]: {
      marginTop: "50px",
    },
  },
  quotesfix: {
    marginTop: "315px",
    [theme.breakpoints.down("md")]: {
      marginTop: "50px",
    },
  },
  follow: {
    position: "absolute",
    top: "0",
    right: "0"
  },
  followIcon: {
    width: "20px",
    height: "20px"
  },
  followButton: {
    marginTop: "-28px !important"
  },
  gridItem: {
    ...mlAuto,
    ...mrAuto
  },
  collections: {
    marginTop: "20px"
  },
  cardBody: {
    display: "flex",
    boxOrient: "horizontal",
    boxDirection: "normal",
    flexDirection: "column",
    boxPack: "left",
    justifyContent: "left",
    minHeight: 280,
    paddingTop: "45px",
    paddingLeft: "20px",
    marginLeft: "0px ",
    marginRight: "0px",
    float: "left",
    "& h4": {
      color: whiteColor + " !important"
    },
    "& p": {
      color: "rgba(" + hexToRgb(whiteColor) + ",0)!important"
    },

  },
  imageAlignment: {
    float: "right",
    "@media (max-width: 576px)": {
      float: "none !important",
    },

    "@media (max-width:500px)": {
      marginLeft: "0px",
    },
  },
  badgecenter: {
    marginLeft: "136px",
    "@media (max-width:500px)": {
      marginLeft: "30px",
    },
  },

  videoAlignmentforcarpet: {


    "@media (max-width:500px)": {
      float: "left !important",
      marginLeft: "12px !important",
      width: "250px !important",
      height: "170px !important"
    },
    "@media screen and (min-width:501px ),(max-width:959px)": {

      marginLeft: "12px !important",

    },

    "@media screen and (min-width:960px ),(max-width:1210px)": {
      float: "left !important",
      marginLeft: "12px !important",
      width: "275px !important",
      height: "186px !important"
    },

    "@media (min-width:1211px)": {
      float: "left !important",
      width: "360px !important",
      height: "220px !important"
    },
  },

  imagecenter: {
    marginLeft: "97px",

    "@media (max-width:500px)": {
      marginLeft: "0px",
    },
  },

  cardBodyBackground: {
    height: "90%",
    "&:after": {
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
      content: '""',
      marginRight: "0",
      backgroundColor: "rgba(" + hexToRgb(blackColor) + ", 0.56)",
      borderRadius: "6px"
    },
  },


  badge: {
    display: "inline-table",
    margin: "0 auto",
    float: "left",
    marginLeft: "0"
  },
  listUnstyled: {
    paddingLeft: "0",
    listStyle: "none",
    "& > li": {
      padding: "5px 0px",
      fontSize: "1em"
    }
  },
  profileTabs: {
    marginTop: "4.284rem",
    marginBottom: "50px"
  },
  card: {
    textAlign: "left !important"
  },
  block: {
    color: "inherit",
    padding: "0.9375rem",
    fontWeight: "500",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block"
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto"
  },
  cardBodyHead: {
    float: "left",
    textAlign: "left"
  },
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0"
  },
  left: {
    float: "left!important",
    display: "block"
  },
  right: {
    padding: "15px 0",
    margin: "0",
    float: "right"
  },
  icon: {
    width: "18px",
    height: "18px",
    top: "3px",
    position: "relative"
  },
  parallax: {
    height: "380px",
    backgroundPosition: "top center"
  },
  h1title:{
    ...title,
    fontSize:'38px'
    },
});

export default profilePageStyle;
