import React from "react";
import { graphql } from "gatsby";
import { Link } from "gatsby";
// nodejs library that concatenates classes
import classNames from "classnames";
import Badge from "components/Badge/Badge.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card";
import Muted from "components/Typography/Muted1.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Layout from "ps-components/Layout/Layout.jsx";
import profilePageStyle from "assets/jss/material-kit-pro-react/views/profilePageStyle.jsx";
import Carousel from "react-slick";
import FormatQuote from "@material-ui/icons/FormatQuote";
import img1 from "ps-assets/img/Kamal.png";
import img2 from "ps-assets/img/Dileep.png";
import img3 from "ps-assets/img/Guruvender.png";
import img4 from "ps-assets/img/Jayesh Ram.png";
import img5 from "ps-assets/img/Manikandan.png";
import img6 from "ps-assets/img/Narasiman.png";
import img7 from "ps-assets/img/NithinKarke.png";
import img8 from "ps-assets/img/Ramachandran.png";
import img9 from "ps-assets/img/SagayaRaj.png";
import img10 from "ps-assets/img/Satyajit.png";
import img11 from "ps-assets/img/shami.png";
import img12 from "ps-assets/img/Srikanthan.png";
import img13 from "ps-assets/img/StarCustomer.png";
import img14 from "ps-assets/img/Sunil.png";
import img15 from "ps-assets/img/Vanisree.png";
import img16 from "ps-assets/img/Vipin-BYTE.png";
import img17 from "ps-assets/img/Leena.png";
import { browserDocument } from "util/localStorageHelper";
import { Helmet } from "react-helmet";

let url = process.env.BASE_URL;
var apivideourl = require(`default.json`).testimonialvideo;
const settings = {
  dots: true,
  infinite: true,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  initialSlide: 0,
  arrows: false
};
var title = ``;


var days = ``;
class Testimonials extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      controls1: false,
      controls2: false,
      controls3: false,
      controls4: false,
      controls5: false,
      controls6: false,
      controls7: false,
      controls8: false,
      controls9: false,
      controls10: false,
      controls11: false,
      controls12: false,
      controls13: false,
      controls14: false,
      controls15: false,
      controls16: false,
      controls17: false,
    };
  }

  componentDidMount() {
    browserDocument.addEventListener(`play`, function (e) {
      var audios = browserDocument.getElementsByTagName(`video`);
      for (var i = 0, len = audios.length; i < len; i++) {
        if (audios[i] != e.target) {
          audios[i].pause();
        }
      }
    }, true);
  }


  render() {


    const { classes, data } = this.props;
    var Images = data.allStrapiImages.edges;
    var bwImage = Images[0];
    var clrImage = Images[1];

    var testimonialdata = data.allStrapiInsuranceplans.edges[0].node.entities[4];
    for (let data in testimonialdata) {
      if (data == `title`) {
        title = <b className={classes.title}  >{` Kamal (Pune)`}</b>;
      }



    }

    return (

      <Layout
        image1={
          bwImage &&
          bwImage.node &&
          bwImage.node.content &&
          bwImage.node.content.childImageSharp.fluid
        }
        image2={
          clrImage &&
          clrImage.node &&
          clrImage.node.content &&
          clrImage.node.content.childImageSharp.fluid
        }
        small={true}
        title={``}
        summary={``}>
          <Helmet key="helmetTags">
        <title>Star Health Insurance Reviews and Ratings | StarHealth.in</title>
      </Helmet>
      {process.env.ENABLE_HELMET == `true` && (
        <Helmet key="helmetTags">
          <link
            rel="canonical"
            href={url + `testimonials`}
          />
          <meta name="title" content="Star Health Insurance Reviews and Ratings | StarHealth.in" />
          <meta name="twitter:title" content="Star Health Insurance Reviews and Ratings | StarHealth.in" />
          <meta
            name="description"
            content="Awards and recognition also come in the form of customer satisfaction through their Star Health reviews. Let’s listen to our customer’s stories."
          />
          <meta
            name="keywords"
            content="Buy Online Health Insurance Plans, Health Insurance, Accident Care, Overseas Travel, Insurance Policy, Insurance Agent Corner, Star Health and Allied Insurance Company Limited, Chennai, India, Portability, Star Health Products, Renew  Policy Online,Star Health Locate us, Health Insurance Specialist In India."
          />
          <meta property="og:title" content="Star Health Insurance Reviews and Ratings | StarHealth.in" />
          <meta property="og:description" content="Awards and recognition also come in the form of customer satisfaction through their Star Health reviews. Let’s listen to our customer’s stories." />
          <meta property="og:url" content={url + `testimonials`} />
          <meta name="Star Health Insurance Reviews and Ratings | StarHealth.in" />
          <meta name="twitter:description" content="Awards and recognition also come in the form of customer satisfaction through their Star Health reviews. Let’s listen to our customer’s stories." />
          <meta property="twitter:url" content={url + `testimonials`} />
        </Helmet>
      )}
        <div
          className={classNames(classes.main, classes.mainRaised)}
          style={{ marginBottom: 100 }}>
          <div className={classes.section}>
            <div className={classes.container} style={{ maxWidth: `1170px` }}>
              <GridContainer
                className={classNames(
                  classes.mrAuto,
                  classes.mlAuto,
                  classes.collections
                )}>

                <h1 className={classes.h1title} style={{ fontFamily: `Roboto Slab`, padding: `10px`, textAlign: `center`, paddingTop: `100px`, width: `100%` }}>
                  {`Star Health reviews from our valued customers`}
                </h1>

                <GridItem
                  md={12}

                  className={`${classes.mrAuto} ${classes.mlAuto} ${
                    classes.textCenter
                  }`}
                  style={{ paddingBottom: `60px`, color: `#3C4858`, textAlign: `center` }}>

                  At Star Health, we place great emphasis on customer satisfaction. It&apos;s encouraging to hear our customers Star Health reviews. We are continually moulding ourselves to provide services a step ahead of what&apos;s expected by keenly listening to our customer voices and constantly observing the Star Health ratings.
                  <br /><br />Let&apos;s hear how our services have benefited the customers across the country.
                </GridItem>
                <GridContainer >
                  <GridItem
                    xs={12}
                    sm={12}
                    md={5}
                    className={classNames(
                      classes.mrAuto,
                      classes.mlAuto,
                      classes.gridItem
                    )}>
                    <div style={{
                      marginTop: `67px`,

                      position: `absolute`
                    }} className={classes.badgecenter}>
                      <a rel="noopener noreferrer" href="#pablo" />

                      <Badge color="rose" className={classes.badge} style={{ marginTop: `32px` }}>
                        Health
                      </Badge>
                      <br />
                      <a rel="noopener noreferrer" href="#pablo">
                        <h4 style={{ color: `white`, fontWeight: `bold` }}>
                          Kamal
                        </h4>
                      </a></div>
                    <br />
                    <br />
                    <div className={classes.imagecenter}>
                      <video width="360" height="340" controls={this.state.controls1} poster={img1} className={`${classes.videoAlignmentforcarpet}`} src={apivideourl + `Kamal.mp4`} style={{ objectFit: `fill`, borderRadius: `10px`, width: `100%` }} onMouseEnter={() => this.setState({ controls1: true })} onMouseLeave={() => this.setState({ controls1: false })}>
                        <source src={apivideourl + `Kamal.mp4`} type="video/mp4" />

                        Your browser does not support the video tag.
                      </video>
                    </div>

                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={7}
                    className={classNames(
                      classes.mrAuto,
                      classes.mlAuto,
                      classes.gridItem
                    )} style={{ padding: `0px 35px` }}>


                    <div>
                      <br />
                      <br />
                      <Badge color="rose">health</Badge>


                      <div style={{ marginTop: `10px` }}>
                        <FormatQuote />
                      </div>
                      <h3>
                        Thank you, Star.
                      </h3>

                      <h4 className={`${classes.mrAuto} ${classes.mlAuto}`} style={{ float: `left`, color: `#3C4858` }}>I received help from Star during my surgery and recovery. And my faith in the company has increased a lot.  </h4>
                      <p
                        className={`${classes.mrAuto} ${classes.mlAuto}`}
                        style={{ float: `left`, color: `#3C4858` }}>
                        by{` `}
                        <a rel="noopener noreferrer" href="#pablo">
                          {title}
                          {days}
                        </a>
                      </p>
                    </div>
                  </GridItem>
                  <GridItem>
                  </GridItem>
                </GridContainer>
                <GridContainer >
                  <GridItem
                    xs={12}
                    sm={12}
                    md={2}

                    className={classNames(
                      classes.mrAuto,
                      classes.mlAuto,
                      classes.gridItem,
                      classes.quotesfix
                    )} style={{ padding: `0px 35px` }} >


                    <hr />
                    <h4 style={{ fontWeight: `bold` }}>Thank you.</h4>
                    <h4 className="description" style={{ color: `#3C4858` }}>
                      We are into our 16th year year of securing lives. We could not have done it alone.
                    </h4>
                    <hr />

                    <Badge color="rose">Health</Badge>

                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={8}
                    className={classNames(
                      classes.mrAuto,
                      classes.mlAuto,
                      classes.textCenter,
                      classes.gridItem
                    )}>
                    <h2 className={classes.testimonialstitlefix}>Why I chose Star?</h2>
                    <br />
                    <GridContainer>
                      <GridItem
                        xs={12}
                        sm={6}
                        md={6}
                        className={`${classes.mlAuto} ${classes.mrAuto} ${
                          classes.textCenter
                        }`} style={{ paddingBottom: `25px` }}>
                        <div style={{
                          marginTop: `20px`,
                          marginLeft: `36px`,
                          position: `absolute`
                        }}>
                          <a rel="noopener noreferrer" href="#pablo" />
                          <Badge color="rose" className={classes.badge} >
                            Health
                          </Badge>
                          <br />
                          <a rel="noopener noreferrer" href="#pablo">
                            <h4 style={{ color: `white`, fontWeight: `bold` }}>
                              Vanisree
                            </h4>
                          </a></div>
                        <video width="360" height="340" controls={this.state.controls2} poster={img15} className={classes.videoAlignmentforcarpet} style={{ objectFit: `fill`, borderRadius: `10px`, width: `100%` }} onMouseEnter={() => this.setState({ controls2: true })} onMouseLeave={() => this.setState({ controls2: false })}>
                          <source src={apivideourl + `Vanisree.mp4`} type="video/mp4" />
                          <source src={apivideourl + `Vanisree.mp4`} type="video/ogg" />
                          Your browser does not support the video tag.
                        </video>
                      </GridItem>
                      <GridItem xs={12} sm={6} md={6} style={{ paddingBottom: `25px` }} >
                        <div style={{
                          marginTop: `20px`,
                          marginLeft: `36px`,
                          position: `absolute`
                        }}>
                          <a rel="noopener noreferrer" href="#pablo" />
                          <Badge color="rose" className={classes.badge} >
                            Health
                          </Badge>
                          <br />
                          <a rel="noopener noreferrer" href="#pablo">
                            <h4 style={{ color: `white`, fontWeight: `bold` }}>
                              Satyajit
                            </h4>
                          </a></div>
                        <div>
                          <video width="360" height="340" className={classes.videoAlignmentforcarpet} controls={this.state.controls10} poster={img10} style={{ objectFit: `fill`, borderRadius: `10px`, width: `100%` }} onMouseEnter={() => this.setState({ controls10: true })} onMouseLeave={() => this.setState({ controls10: false })}>
                            <source src={apivideourl + `Satyajit.mp4`} type="video/mp4" />
                            <source src={apivideourl + `Satyajit.mp4`} type="video/ogg" />
                            Your browser does not support the video tag.
                          </video>
                        </div>

                      </GridItem>
                      <GridItem xs={12} sm={6} md={6} style={{ paddingBottom: `25px` }} >
                        <div style={{
                          marginTop: `20px`,
                          marginLeft: `36px`,
                          position: `absolute`
                        }}>
                          <a rel="noopener noreferrer" href="#pablo" />
                          <Badge color="rose" className={classes.badge} >
                            Health
                          </Badge>
                          <br />
                          <a rel="noopener noreferrer" href="#pablo">
                            <h4 style={{ color: `white`, fontWeight: `bold` }}>
                              Jeyesh Ram
                            </h4>
                          </a></div>
                        <div>
                          <video width="360" height="340" controls={this.state.controls4} poster={img4} className={classes.videoAlignmentforcarpet} style={{ objectFit: `fill`, borderRadius: `10px`, width: `100%` }} onMouseEnter={() => this.setState({ controls4: true })} onMouseLeave={() => this.setState({ controls4: false })}>
                            <source src={apivideourl + `JeyeshRAM.mp4`} type="video/mp4" />
                            <source src={apivideourl + `JeyeshRAM.mp4`} type="video/ogg" />
                            Your browser does not support the video tag.
                          </video>
                        </div>
                      </GridItem>
                      <GridItem xs={12} sm={6} md={6} style={{ paddingBottom: `25px` }} >
                        <a rel="noopener noreferrer" href="#pablo" />
                        <div style={{
                          marginTop: `20px`,
                          marginLeft: `36px`,
                          position: `absolute`
                        }}>
                          <a rel="noopener noreferrer" href="#pablo" />
                          <Badge color="rose" className={classes.badge} >
                            Health
                          </Badge>
                          <br />
                          <a rel="noopener noreferrer" href="#pablo">
                            <h4 style={{ color: `white`, fontWeight: `bold` }}>
                              Ramachandran
                            </h4>
                          </a></div>
                        <div>
                          <video width="360" height="340" controls={this.state.controls5} poster={img8} className={classes.videoAlignmentforcarpet} style={{ objectFit: `fill`, borderRadius: `10px`, width: `100%` }} onMouseEnter={() => this.setState({ controls5: true })} onMouseLeave={() => this.setState({ controls5: false })}>
                            <source src={apivideourl + `Ramachandran.mp4`} type="video/mp4" />
                            <source src={apivideourl + `Ramachandran.mp4`} type="video/ogg" />
                            Your browser does not support the video tag.
                          </video>
                        </div>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
                <GridItem
                  xs={12}
                  sm={12}
                  md={12}
                  className={`${classes.mlAuto} ${classes.mrAuto} ${
                    classes.textCenter
                  } ${classes.gridItem}`} style={{ paddingTop: `70px` }}>
                  <h2 className={classes.title}>A few more Star Health reviews</h2>
                  <h5 style={{ color: `#3C4858` }}>
                    <span>
                      Hear from some more customers who have been joining the Star
                      family, every day.
                    </span>
                  </h5>
                </GridItem>
                <GridItem
                  xs={12}
                  sm={12}
                  md={12}
                  className={`${classes.mlAuto} ${classes.mrAuto} ${
                    classes.gridItem
                  }`}>
                  <Carousel {...settings} className={classes.customCarousel} style={{ width: 0 }}>
                    <div>
                      <GridContainer>
                        <GridItem xs={12} sm={6} md={6}>
                          <div
                            style={{
                              marginTop: `20%`
                            }}>
                            <Card profile plain className={classes.card3}>
                              <GridContainer>
                                <GridItem
                                  xs={12}
                                  sm={5}
                                  md={5}
                                  style={{ paddingLeft: `20px` }}>
                                  <CardHeader image plain>
                                    <a rel="noopener noreferrer"
                                      href="#pablo"
                                      onClick={e => e.preventDefault()}>
                                      <div>
                                        <video controls={this.state.controls6} poster={img14} style={{ objectFit: `fill`, borderRadius: `10px`, width: `100%` }} onMouseEnter={() => this.setState({ controls6: true })} onMouseLeave={() => this.setState({ controls6: false })}>
                                          <source src={apivideourl + `Sunil.mp4`} type="video/mp4" />
                                          <source src={apivideourl + `Sunil.mp4`} type="video/ogg" />
                                          Your browser does not support the video tag.
                                        </video>
                                      </div>
                                    </a>
                                  </CardHeader>
                                </GridItem>
                                <GridItem xs={12} sm={7} md={7}>
                                  <CardBody plain style={{ padding: `0px` }}>
                                    <h4
                                      className={classes.cardTitle}
                                      style={{ float: `left`, margin: `0px` }}>
                                      Sunil
                                    </h4>


                                    <Muted>

                                      <p
                                        className={classes.description}
                                        style={{ textAlign: `left`, margin: `0px` }}>
                                        <FormatQuote style={{ color: `#3C4858` }} />
                                        <br />
                                        <div style={{color: `#3C4858`}} > I have had a great experience with Star and I received my claim amount on the same day.
                                        </div>
                                      </p>
                                    </Muted>
                                  </CardBody>
                                </GridItem>
                              </GridContainer>
                            </Card>
                          </div>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6}>
                          <div
                            style={{
                              marginTop: `20%`
                            }}>
                            <Card profile plain className={classes.card3}>
                              <GridContainer>
                                <GridItem
                                  xs={12}
                                  sm={5}
                                  md={5}
                                  style={{ paddingLeft: `20px` }}>
                                  <CardHeader image plain>
                                    <a rel="noopener noreferrer"
                                      href="#pablo"
                                      onClick={e => e.preventDefault()}>
                                      <video controls={this.state.controls7} poster={img17} style={{ objectFit: `fill`, borderRadius: `10px`, width: `100%` }} onMouseEnter={() => this.setState({ controls7: true })} onMouseLeave={() => this.setState({ controls7: false })}>
                                        <source src={apivideourl + `Leena.mp4`} type="video/mp4" />
                                        <source src={apivideourl + `Leena.mp4`} type="video/ogg" />
                                        Your browser does not support the video tag.
                                      </video>
                                    </a>
                                  </CardHeader>
                                </GridItem>
                                <GridItem xs={12} sm={7} md={7}>
                                  <CardBody plain style={{ padding: `0px` }}>
                                    <h4
                                      className={classes.cardTitle}
                                      style={{ float: `left`, margin: `0px` }}>
                                      Leena
                                    </h4>
                                    <Muted>
                                      <p
                                        className={classes.description}
                                        style={{ textAlign: `left`, margin: `0px` }}>
                                        <FormatQuote style={{ color: `#3C4858` }} />
                                        <br />
                                        <div style={{color: `#3C4858`}} >Star Health has been efficient in giving innovative products, reaching out to the people and clients with good claim services.
                                        </div>
                                      </p>
                                    </Muted>
                                  </CardBody>
                                </GridItem>
                              </GridContainer>
                            </Card>
                          </div>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6}>
                          <div style={{ marginBottom: `15%` }}>
                            <Card profile plain className={classes.card3}>
                              <GridContainer>
                                <GridItem
                                  xs={12}
                                  sm={5}
                                  md={5}
                                  style={{ paddingLeft: `20px` }}>
                                  <CardHeader image plain>
                                    <a rel="noopener noreferrer"
                                      href="#pablo"
                                      onClick={e => e.preventDefault()}>
                                      <video controls={this.state.controls8} poster={img3} style={{ objectFit: `fill`, borderRadius: `10px`, width: `100%` }} onMouseEnter={() => this.setState({ controls8: true })} onMouseLeave={() => this.setState({ controls8: false })}>
                                        <source src={apivideourl + `Guruvender.mp4`} type="video/mp4" />
                                        <source src={apivideourl + `Guruvender.mp4`} type="video/ogg" />
                                        Your browser does not support the video tag.
                                      </video>
                                    </a>
                                  </CardHeader>
                                </GridItem>
                                <GridItem xs={12} sm={7} md={7}>
                                  <CardBody plain style={{ padding: `0px` }}>
                                    <h4
                                      className={classes.cardTitle}
                                      style={{ float: `left`, margin: `0px` }}>
                                      Guruvender
                                    </h4>
                                    <Muted>
                                      <p
                                        className={classes.description}
                                        style={{ textAlign: `left`, margin: `0px` }}>
                                        <FormatQuote style={{ color: `#3C4858` }} />
                                        <br />
                                        <div style={{color: `#3C4858`}} > I have claimed my premium once when my daughter fell ill, ... and I received the claim in less than 2 hours.</div>
                                      </p>
                                    </Muted>
                                  </CardBody>
                                </GridItem>
                              </GridContainer>
                            </Card>
                          </div>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6}>
                          <div style={{ marginBottom: `15%` }}>
                            <Card profile plain className={classes.card3}>
                              <GridContainer>
                                <GridItem
                                  xs={12}
                                  sm={5}
                                  md={5}
                                  style={{ paddingLeft: `20px` }}>
                                  <CardHeader image plain>
                                    <a rel="noopener noreferrer"
                                      href="#pablo"
                                      onClick={e => e.preventDefault()}>
                                      <video controls={this.state.controls9} poster={img6} style={{ objectFit: `fill`, borderRadius: `10px`, width: `100%` }} onMouseEnter={() => this.setState({ controls9: true })} onMouseLeave={() => this.setState({ controls9: false })}>
                                        <source src={apivideourl + `Narasiman.mp4`} type="video/mp4" />
                                        <source src={apivideourl + `Narasiman.mp4`} type="video/ogg" />
                                        Your browser does not support the video tag.
                                      </video>
                                    </a>
                                  </CardHeader>
                                </GridItem>
                                <GridItem xs={12} sm={7} md={7}>
                                  <CardBody plain style={{ padding: `0px` }}>
                                    <h4
                                      className={classes.cardTitle}
                                      style={{ float: `left`, margin: `0px` }}>
                                      Narasiman
                                    </h4>
                                    <Muted>
                                      <p
                                        className={classes.description}
                                        style={{ textAlign: `left`, margin: `0px` }}>
                                        <FormatQuote style={{ color: `#3C4858` }} />
                                        <br />
                                        <div style={{color: `#3C4858`}} >There was no hurdle in the process and it was very, very easy.  
                                        </div>
                                      </p>
                                    </Muted>
                                  </CardBody>
                                </GridItem>
                              </GridContainer>
                            </Card>
                          </div>
                        </GridItem>
                      </GridContainer>
                    </div>
                    <div>
                      <GridContainer>
                        <GridItem xs={12} sm={6} md={6}>
                          <div
                            style={{


                              marginTop: `20%`
                            }}>
                            <Card profile plain className={classes.card3}>
                              <GridContainer>
                                <GridItem
                                  xs={12}
                                  sm={5}
                                  md={5}
                                  style={{ paddingLeft: `20px` }}>
                                  <CardHeader image plain>
                                    <a rel="noopener noreferrer"
                                      href="#pablo"
                                      onClick={e => e.preventDefault()}>

                                      <video controls={this.state.controls3} poster={img2} style={{ objectFit: `fill`, borderRadius: `10px`, width: `100%` }} onMouseEnter={() => this.setState({ controls3: true })} onMouseLeave={() => this.setState({ controls3: false })}>
                                        <source src={apivideourl + `Dileep.mp4`} type="video/mp4" />
                                        <source src={apivideourl + `Dileep.mp4`} type="video/ogg" />
                                        Your browser does not support the video tag.
                                      </video>
                                    </a>
                                  </CardHeader>
                                </GridItem>
                                <GridItem xs={12} sm={7} md={7}>
                                  <CardBody plain style={{ padding: `0px` }}>
                                    <h4
                                      className={classes.cardTitle}
                                      style={{ float: `left`, margin: `0px` }}>
                                      Dileep
                                    </h4>
                                    <Muted>
                                      <p
                                        className={classes.description}
                                        style={{ textAlign: `left`, margin: `0px` }}>
                                        <FormatQuote style={{ color: `#3C4858` }} />
                                        <br />
                                        <div style={{color: `#3C4858`}}>
                                        I am very grateful to Star and happy that I chose them.
                                        </div>
                                      </p>
                                    </Muted>
                                  </CardBody>
                                </GridItem>
                              </GridContainer>
                            </Card>
                          </div>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6}>
                          <div
                            style={{


                              marginTop: `20%`
                            }}>
                            <Card profile plain className={classes.card3}>
                              <GridContainer>
                                <GridItem
                                  xs={12}
                                  sm={5}
                                  md={5}
                                  style={{ paddingLeft: `20px` }}>
                                  <CardHeader image plain>
                                    <a rel="noopener noreferrer"
                                      href="#pablo"
                                      onClick={e => e.preventDefault()}>
                                      <video controls={this.state.controls11} poster={img7} style={{ objectFit: `fill`, borderRadius: `10px`, width: `100%` }} onMouseEnter={() => this.setState({ controls11: true })} onMouseLeave={() => this.setState({ controls11: false })}>
                                        <source src={apivideourl + `NithinKarke.mp4`} type="video/mp4" />
                                        <source src={apivideourl + `NithinKarke.mp4`} type="video/ogg" />
                                        Your browser does not support the video tag.
                                      </video>
                                    </a>
                                  </CardHeader>
                                </GridItem>
                                <GridItem xs={12} sm={7} md={7}>
                                  <CardBody plain style={{ padding: `0px` }}>
                                    <h4
                                      className={classes.cardTitle}
                                      style={{ float: `left`, margin: `0px` }}>
                                      Nithin Karke
                                    </h4>
                                    <Muted>
                                      <p
                                        className={classes.description}
                                        style={{ textAlign: `left`, margin: `0px` }}>
                                        <FormatQuote style={{ color: `#3C4858` }} />
                                        <br />
                                        <div style={{color: `#3C4858`}}>

                                        Star was very helpful in clearing my claim in a very seamless way.
                                        </div>
                                      </p>
                                    </Muted>
                                  </CardBody>
                                </GridItem>
                              </GridContainer>
                            </Card>
                          </div>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6}>
                          <div style={{ marginBottom: `15%` }}>
                            <Card profile plain className={classes.card3}>
                              <GridContainer>
                                <GridItem
                                  xs={12}
                                  sm={5}
                                  md={5}
                                  style={{ paddingLeft: `20px` }}>
                                  <CardHeader image plain>
                                    <a rel="noopener noreferrer"
                                      href="#pablo"
                                      onClick={e => e.preventDefault()}>
                                      <video controls={this.state.controls12} poster={img12} style={{ objectFit: `fill`, borderRadius: `10px`, width: `100%` }} onMouseEnter={() => this.setState({ controls12: true })} onMouseLeave={() => this.setState({ controls12: false })}>
                                        <source src={apivideourl + `Srikanthan.mp4`} type="video/mp4" />
                                        <source src={apivideourl + `Srikanthan.mp4`} type="video/ogg" />
                                        Your browser does not support the video tag.
                                      </video>
                                    </a>
                                  </CardHeader>
                                </GridItem>
                                <GridItem xs={12} sm={7} md={7}>
                                  <CardBody plain style={{ padding: `0px` }}>
                                    <h4
                                      className={classes.cardTitle}
                                      style={{ float: `left`, margin: `0px` }}>
                                      Srikanthan
                                    </h4>
                                    <Muted>
                                      <p
                                        className={classes.description}
                                        style={{ textAlign: `left`, margin: `0px` }}>
                                        <FormatQuote style={{ color: `#3C4858` }} />
                                        <br />
                                        <div style={{color: `#3C4858`}}>
                                        
                                        If I am alive today, it is all thanks to Star Health.
                                        </div>
                                      </p>
                                    </Muted>
                                  </CardBody>
                                </GridItem>
                              </GridContainer>
                            </Card>
                          </div>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6}>
                          <div style={{ marginBottom: `15%` }}>
                            <Card profile plain className={classes.card3}>
                              <GridContainer>
                                <GridItem
                                  xs={12}
                                  sm={5}
                                  md={5}
                                  style={{ paddingLeft: `20px` }}>
                                  <CardHeader image plain>
                                    <a rel="noopener noreferrer"
                                      href="#pablo"
                                      onClick={e => e.preventDefault()}>
                                      <video controls={this.state.controls13} poster={img5} style={{ objectFit: `fill`, borderRadius: `10px`, width: `100%` }} onMouseEnter={() => this.setState({ controls13: true })} onMouseLeave={() => this.setState({ controls13: false })}>
                                        <source src={apivideourl + `Manikandan.mp4`} type="video/mp4" />
                                        <source src={apivideourl + `Manikandan.mp4`} type="video/ogg" />
                                        Your browser does not support the video tag.
                                      </video>
                                    </a>
                                  </CardHeader>
                                </GridItem>
                                <GridItem xs={12} sm={7} md={7}>
                                  <CardBody plain style={{
                                    padding: `0px`
                                  }}>
                                    <h4
                                      className={classes.cardTitle}
                                      style={{ float: `left`, margin: `0px` }}>
                                      Manikandan Nair
                                    </h4>
                                    <Muted style={{ float: `left` }}>
                                      <p
                                        className={classes.description}
                                        style={{ textAlign: `left`, margin: `0px` }}>
                                        <FormatQuote style={{ color: `#3C4858` }} />
                                        <br />
                                        <div style={{color: `#3C4858`}}>
                                        
                                        I feel Star&rsquo;s service is very good.

                                        </div>
                                      </p>

                                    </Muted>
                                  </CardBody>
                                </GridItem>
                              </GridContainer>
                            </Card>
                          </div>
                        </GridItem>
                      </GridContainer>
                    </div>
                    <div>
                      <GridContainer>
                        <GridItem xs={12} sm={6} md={6}>
                          <div
                            style={{


                              marginTop: `20%`
                            }}>
                            <Card profile plain className={classes.card3}>
                              <GridContainer>
                                <GridItem
                                  xs={12}
                                  sm={5}
                                  md={5}
                                  style={{ paddingLeft: `20px` }}>
                                  <CardHeader image plain>
                                    <a rel="noopener noreferrer"
                                      href="#pablo"
                                      onClick={e => e.preventDefault()}>
                                      <video controls={this.state.controls14} poster={img13} style={{ objectFit: `fill`, borderRadius: `10px`, width: `100%` }} onMouseEnter={() => this.setState({ controls14: true })} onMouseLeave={() => this.setState({ controls14: false })}>
                                        <source src={apivideourl + `StarCustomer.mp4`} type="video/mp4" />
                                        <source src={apivideourl + `StarCustomer.mp4`} type="video/ogg" />
                                        Your browser does not support the video tag.
                                      </video>
                                    </a>
                                  </CardHeader>
                                </GridItem>
                                <GridItem xs={12} sm={7} md={7}>
                                  <CardBody plain style={{ padding: `0px` }}>
                                    <h4
                                      className={classes.cardTitle}
                                      style={{ float: `left`, margin: `0px` }}>
                                      Star Customer
                                    </h4>
                                    <Muted>
                                      <p
                                        className={classes.description}
                                        style={{ textAlign: `left`, margin: `0px` }}>
                                        <FormatQuote style={{ color: `#3C4858` }} />
                                        <br />
                                        <div style={{color: `#3C4858`}}>

                                        Star&rsquo;s representatives and medical team was extremely efficient and ensured that I received the best care.
                                        </div>
                                      </p>
                                    </Muted>
                                  </CardBody>
                                </GridItem>
                              </GridContainer>
                            </Card>
                          </div>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6}>
                          <div
                            style={{


                              marginTop: `20%`
                            }}>
                            <Card profile plain className={classes.card3}>
                              <GridContainer>
                                <GridItem
                                  xs={12}
                                  sm={5}
                                  md={5}
                                  style={{ paddingLeft: `20px` }}>
                                  <CardHeader image plain>
                                    <a rel="noopener noreferrer"
                                      href="#pablo"
                                      onClick={e => e.preventDefault()}>
                                      <video controls={this.state.controls15} poster={img16} style={{ objectFit: `fill`, borderRadius: `10px`, width: `100%` }} onMouseEnter={() => this.setState({ controls15: true })} onMouseLeave={() => this.setState({ controls15: false })}>
                                        <source src={apivideourl + `Vipin-BYTE.mp4`} type="video/mp4" />
                                        <source src={apivideourl + `Vipin-BYTE.mp4`} type="video/ogg" />
                                        Your browser does not support the video tag.
                                      </video>
                                    </a>
                                  </CardHeader>
                                </GridItem>
                                <GridItem xs={12} sm={7} md={7}>
                                  <CardBody plain style={{ padding: `0px` }}>
                                    <h4
                                      className={classes.cardTitle}
                                      style={{ float: `left`, margin: `0px` }}>
                                      Vipin Samak
                                    </h4>
                                    <Muted style={{ float: `left` }}>
                                      <p
                                        className={classes.description}
                                        style={{ textAlign: `left`, margin: `0px` }}>
                                        <FormatQuote style={{ color: `#3C4858` }} />
                                        <br />
                                        <div style={{color: `#3C4858`}}>

                                        Star&rsquo;s cashless facility was extremely useful.
                                        </div>
                                      </p>
                                    </Muted>
                                  </CardBody>
                                </GridItem>
                              </GridContainer>
                            </Card>
                          </div>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6}>
                          <div style={{ marginBottom: `15%` }}>
                            <Card profile plain className={classes.card3}>
                              <GridContainer>
                                <GridItem
                                  xs={12}
                                  sm={5}
                                  md={5}
                                  style={{ paddingLeft: `20px` }}>
                                  <CardHeader image plain>
                                    <a rel="noopener noreferrer"
                                      href="#pablo"
                                      onClick={e => e.preventDefault()}>
                                      <video controls={this.state.controls16} poster={img11} style={{ objectFit: `fill`, borderRadius: `10px`, width: `100%` }} onMouseEnter={() => this.setState({ controls16: true })} onMouseLeave={() => this.setState({ controls16: false })}>
                                        <source src={apivideourl + `Shami.mp4`} type="video/mp4" />
                                        <source src={apivideourl + `Shami.mp4`} type="video/ogg" />
                                        Your browser does not support the video tag.
                                      </video>
                                    </a>
                                  </CardHeader>
                                </GridItem>
                                <GridItem xs={12} sm={7} md={7}>
                                  <CardBody plain style={{ padding: `0px` }}>
                                    <h4
                                      className={classes.cardTitle}
                                      style={{ float: `left`, margin: `0px` }}>
                                      Shami
                                    </h4>
                                    <Muted>
                                      <p
                                        className={classes.description}
                                        style={{ textAlign: `left`, margin: `0px` }}>
                                        <FormatQuote style={{ color: `#3C4858` }} />
                                        <br />
                                        <div style={{color: `#3C4858`}}>

                                        I was pleasantly surprised to find that they hve a special package even for cardiac patients.
                                        </div>
                                      </p>
                                    </Muted>
                                  </CardBody>
                                </GridItem>
                              </GridContainer>
                            </Card>
                          </div>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6}>
                          <div style={{ marginBottom: `15%` }}>
                            <Card profile plain className={classes.card3}>
                              <GridContainer>
                                <GridItem
                                  xs={12}
                                  sm={5}
                                  md={5}
                                  style={{ paddingLeft: `20px` }}>
                                  <CardHeader image plain>
                                    <a rel="noopener noreferrer"
                                      href="#pablo"
                                      onClick={e => e.preventDefault()}>
                                      <video controls={this.state.controls17} poster={img9} style={{ objectFit: `fill`, borderRadius: `10px`, width: `100%` }} onMouseEnter={() => this.setState({ controls17: true })} onMouseLeave={() => this.setState({ controls17: false })}>
                                        <source src={apivideourl + `SagayaRaj.mp4`} type="video/mp4" />
                                        <source src={apivideourl + `SagayaRaj.mp4`} type="video/ogg" />
                                        Your browser does not support the video tag.
                                      </video>
                                    </a>
                                  </CardHeader>
                                </GridItem>
                                <GridItem xs={12} sm={7} md={7}>
                                  <CardBody plain style={{ padding: `0px` }}>
                                    <h4
                                      className={classes.cardTitle}
                                      style={{ float: `left`, margin: `0px` }}>
                                      Sagaya Raj
                                    </h4>
                                    <Muted>
                                      <p
                                        className={classes.description}
                                        style={{ textAlign: `left`, margin: `0px` }}>
                                        <FormatQuote style={{ color: `#3C4858` }} />
                                        <br />
                                        <div style={{color: `#3C4858`}}>

                                        I have had no hassles in claims and their assistance during hospitalization is excellent.
                                        </div>
                                      </p>
                                    </Muted>
                                  </CardBody>
                                </GridItem>
                              </GridContainer>
                            </Card>
                          </div>
                        </GridItem>
                      </GridContainer>
                    </div>
                  </Carousel>
                </GridItem>


                <GridItem
                  xs={12}
                  sm={12}
                  md={12}
                  className={`${classes.mlAuto} ${classes.mrAuto} ${
                    classes.textCenter
                  }`} style={{ paddingBottom: `105px` }}>
                  <h2 className={classes.title}>
                  We’d love to hear your Star Health review
                  </h2>
                  <Link to="/contact-us/?query=3">
                    <Button
                      round
                      color="primary"
                      style={{ padding: `10px 15px 10px 15px` }}>
                      Share your review
                    </Button>

                  </Link>

                </GridItem>

              </GridContainer>
            </div>
          </div>
        </div>

      </Layout >

    );
  }
}
Testimonials.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
};

export default withStyles(profilePageStyle)(Testimonials);

export const termsQuery = graphql`
  query Testimonials {
    allStrapiInsuranceplans(filter : {  productId : {eq : 7 }}){
      edges{
        node{
          entities{
            title
            description
            url
            category
            searchKey
            summary
            days
          }
        }
      }
    }
    allStrapiImages(filter: { category: { eq: "testimonials" } }) {
      edges {
        node {
          content {
            publicURL
            childImageSharp{
              fluid(quality:100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }

  }
`;
